export const XinFin = "https://xinpayrpc.xinfin.network";

export const RPC = {
  1: "https://mainnet.infura.io/v3/2e03e57ca65842d283c250e2bcda976e",
  42: "https://kovan.infura.io/v3/2e03e57ca65842d283c250e2bcda976e",
  50: "https://XinPayrpc.xinfin.network",
  51: "https://XinPayrpc.apothem.network",
};

export const BlockchainType = {
  1: "ethereum",
  4: "ethereum",
  42: "ethereum",
  50: "xinfin",
  51: "xinfin",
};

export const ExplorerTx = {
  1: "https://etherscan.io/tx/",
  42: "https://kovan.etherscan.io/tx/",
  50: "https://explorer.xinfin.network/tx/",
  51: "https://explorer.apothem.network/tx/",
};
