import Xdc3, { utils } from "xdc3";

import { RPC, XinFin } from "../helpers/rpc";

const XinFinUnitMap = Object.keys(utils.unitMap).reduce((acc, cur) => {
  let xinfinSymbol = cur;
  if (xinfinSymbol.includes("ether"))
    xinfinSymbol = xinfinSymbol.replace("ether", "xdc");
  acc[cur] = xinfinSymbol;
  return acc;
}, {});

export const PublishRawTx = (tx, chainId) => {
  const rpc = RPC[`${chainId}`];
  const xdc3 = new Xdc3(new Xdc3.providers.HttpProvider(rpc));
  return xdc3.eth.sendSignedTransaction(tx);
};

export const FromWei = (n, to = "ether") => {
  return utils.fromWei(n, to);
};

export const GetAccountNonce = (
  address,
  config = { chainId: 50, type: "latest" }
) => {
  const chainId = config.chainId || 50;
  const type = config.type || "latest";
  const rpc = RPC[`${chainId}`];
  const xdc3 = new Xdc3(new Xdc3.providers.HttpProvider(rpc));
  return xdc3.eth.getTransactionCount(ToEthAddress(address), type);
};

export const GetAccountBalance = (
  address,
  config = { chainId: 50, type: "latest" }
) => {
  const chainId = config.chainId || 50;
  const rpc = RPC[`${chainId}`];
  const xdc3 = new Xdc3(new Xdc3.providers.HttpProvider(rpc));
  return xdc3.eth.getBalance(ToEthAddress(address));
};

export const IsAddress = (address = "") => {
  return utils.isAddress(address);
};

export const ToEthAddress = (address = "") => {
  if (address.startsWith("xdc")) address = "0x" + address.slice(3);
  return address;
};

export const ToXdcAddress = (address = "") => {
  if (address.startsWith("0x")) address = "xdc" + address.slice(2);
  return address;
};

export const UnitMap = (unit) => {
  return XinFinUnitMap[unit];
};

export const ExplorerTx = "https://explorer.xinfin.network/tx/";
