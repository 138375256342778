import React from "react";

import RawTxDecoder from "../components/RawTxDecoder";
import Converter from "../components/Converter";

export default function () {
  return (
    <div className="dashboard">
      <RawTxDecoder />
      <Converter />
    </div>
  );
}
