import Dashboard from "./HOC/Dashboard";
import Test from "./components/QRReader";

import { Switch, Route, Redirect } from "react-router-dom";

import "./assets/scss/main.scss";
import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <Header />

      <Switch>
        <Route exact path="/raw-tx">
          <Dashboard />
        </Route>

        {/* <Route exact path="/converter">
          <></>
        </Route>

        <Route exact path="/stablecoins">
          <></>
        </Route> */}

        <Route path="/">
          <Redirect to="raw-tx" />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
