import { BlockchainType } from "./address";
import { UnitMap as UnitMapXdc3 } from "./xdc3";
import { UnitMap as UnitMapWeb3 } from "./web3";

export const GetEquivalentSymbol = (symbol, type) => {
  if (type === BlockchainType.xinfin) {
    return UnitMapXdc3(symbol);
  } else {
    return UnitMapWeb3(symbol);
  }
};
