import {
  GetAccountNonce as GetAccountNonceXdc3,
  IsAddress as IsAddressXdc,
  ToEthAddress,
  GetAccountBalance as GetAccountBalanceXdc3
} from "../utilities/xdc3";
import {
  GetAccountNonce as GetAccountNonceWeb3,
  IsAddress as IsAddressEth,
  GetAccountBalance as GetAccountBalanceWeb3
} from "../utilities/web3";
import { isAddress } from "ethers/lib/utils";

export const BlockchainType = {
  ethereum: "ethereum",
  xinfin: "xinfin",
};

export const GetBlockchainType = (address = "") => {
  if (address.startsWith("xdc")) return BlockchainType.xinfin;
  if (address.startsWith("0x")) return BlockchainType.ethereum;
  return "invalid";
};

export const GetBlockchainFromChainId = (chainId) => {
  switch (`${chainId}`) {
    case '50': return BlockchainType.xinfin;
    case '1': return BlockchainType.ethereum;
    default: return null;
  }
};

export const GetNonce = async (address, type) => {
  switch (type) {
    case BlockchainType.ethereum: {
      return await GetAccountNonceWeb3(address);
    }
    case BlockchainType.xinfin: {
      return await GetAccountNonceXdc3(address);
    }
    default:
      break;
  }
};

export const GetBalance = async (address, type) => {
  switch (type) {
    case BlockchainType.ethereum: {
      return await GetAccountBalanceWeb3(address);
    }
    case BlockchainType.xinfin: {
      return await GetAccountBalanceXdc3(address);
    }
    default:
      break;
  }
};

export const IsValid = (address = "") => {
  return IsAddressEth(address) || IsAddressXdc(ToEthAddress(address));
};
