import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

import {
  GetBlockchainType,
  GetNonce as GetNonceFunc,
  IsValid,
} from "../utilities/address";

const utilities = [{ eventKey: 1, label: "Nonce" }];

const GetNonce = function () {
  const [address, setAddress] = useState({
    value: "",
    type: null,
    nonce: "-",
    pendingNonce: "-",
  });

  return (
    <Container>
      <Row className="custom-input-1">
        <Col lg={3} sm={3}>
          <label>Address</label>
        </Col>
        <Col lg={9} sm={9}>
          <input
            className="form-comtrol"
            value={address.value}
            onChange={async (e) => {
              let type = GetBlockchainType(e.target.value);
              const isValid = IsValid(e.target.value);
              if (isValid === false) {
                type = "invalid";
              }
              const updtState = {
                ...address,
                value: e.target.value,
                type: type,
                nonce: "-",
                pendingNonce: "-",
              };
              if (type !== "invalid") {
                updtState.nonce = await GetNonceFunc(updtState.value, type);
              }
              setAddress({ ...updtState });
            }}
          />
        </Col>
      </Row>
      <Row className="custom-input-1">
        <Col lg={3} sm={3}>
          <label>Type</label>
        </Col>
        <Col lg={9} sm={9}>
          <input
            className="form-comtrol"
            value={address.type}
            disabled={true}
          />
        </Col>
      </Row>
      <Row className="custom-input-1">
        <Col lg={3} sm={3}>
          <label>Nonce</label>
        </Col>
        <Col lg={9} sm={9}>
          <input
            className="form-comtrol"
            value={address.nonce}
            disabled={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

function renderFunctionBody({ eventKey }, address, cb) {
  if (eventKey <= 0) {
    return <div>Select A Function to Continue</div>;
  }
  switch (eventKey) {
    case `1`: {
      return <GetNonce />;
    }
    case `2`: {
      return (
        <Row>
          <Col></Col>
          <Col></Col>
        </Row>
      );
    }
  }
}

export default function () {
  const [utility, setUtility] = useState({
    label: "Select Utility",
    eventKey: 0,
  });

  return (
    <div className="converter">
      <Card className="custom-card-1">
        <Card.Body>
          <Container>
            <div className="title">
              Utilities<div className="title--bottom"></div>
            </div>

            <div className="card-body custom-input-1">
              <Row className="converter--function-selector">
                <Col>
                  <label className="form-comtrol">Select Functionality</label>
                </Col>

                <Col>
                  <Dropdown
                    onSelect={(e, eObj) => {
                      setUtility({ eventKey: e, label: eObj.target.innerText });
                    }}
                  >
                    <DropdownButton title={utility.label}>
                      {utilities.map((e, i) => (
                        <Dropdown.Item
                          eventKey={e.eventKey}
                          active={utility.eventKey == e.eventKey}
                        >
                          {e.label}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Dropdown>
                </Col>
              </Row>

              <Row className="converter--function-body">
                {renderFunctionBody(utility)}
              </Row>
            </div>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
}
