const Text = {
  RawTx: {
    inputPlaceholder: "Enter Raw Tx",
    enterTx: "Enter TX",
    reset: "Reset",
    inavlidData: "Invalid Data",
    chainId: "Chain ID",
    fromAddress: "From Address",
    toAddress: "To Address",
    value: "Value",
    nonce: "Nonce",
    gasPrice: "Gas Price",
    gasLimit: "Gas Limit",
    data: "Data",
    currentNonce: "Current Nonce",
    enterInput: "Enter Input",
  },
};

export default Text;
