import AbiDecoder from "abi-decoder";

import StablecoinAbi from "../abi/stablecoin";
import Erc20Abi from "../abi/erc20";
import StorXABI from "../abi/storx";
import ReputationFeedABI from "../abi/reputationFeed";
import StakingABI from "../abi/staking";

AbiDecoder.addABI(StablecoinAbi);
AbiDecoder.addABI(Erc20Abi);
AbiDecoder.addABI(StorXABI);
AbiDecoder.addABI(ReputationFeedABI);
AbiDecoder.addABI(StakingABI);

export const DecodeData = (data, abi = null) => {
  try {
    const exisintgAbi = AbiDecoder.getABIs();
    if (abi && !exisintgAbi.includes(abi)) AbiDecoder.addABI(abi);

    const decoded = AbiDecoder.decodeMethod(data);

    return (
      <div className="decoded-table">
        <table>
          <thead>
            <tr>
              <th className="decoded-table--field">Function Name</th>
              <th className="decoded-table--value" colSpan={2}>
                {decoded.name}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr key={0}>
              <th>Parameter</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
            {decoded.params.map((e, i) => (
              <tr key={i + 1}>
                <td>{e.name}</td>
                <td>{e.type}</td>
                <td>{e.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } catch (e) {
    return <></>;
  }
};
