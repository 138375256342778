import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import XdcIcon from "../assets/img/xdc-favicon/android-icon-36x36.png";

import { Link } from "react-router-dom";

function Header() {
  return (
    <Navbar className="custom-header" bg="light" expand="lg">
      <Link className="navbar-brand" to="/raw-tx">
        <img src={XdcIcon} />
        XDC Broadcaster
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {/* <Nav className="mr-auto">
          <Link className="nav-link" to="/raw-tx">
            Raw Tx
          </Link>
          <Link className="nav-link" to="/converter">
            Converter
          </Link>
          <Link className="nav-link" to="/stablecoins">
            Stablecoin
          </Link>
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
