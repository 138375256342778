import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { ethers } from "ethers";
import _ from "lodash";

import QRReader from "./QRReader";
import {
  FromWei,
  PublishRawTx as PublishRawTxXdc,
  ToXdcAddress,
} from "../utilities/xdc3";

import { PublishRawTx as PublishRawTxWeb3 } from "../utilities/web3";

import Text from "../helpers/text";
import { DecodeData } from "../utilities/tokenInteraction";
import { ExplorerTx } from "../helpers/rpc";
import {
  GetBlockchainFromChainId,
  GetNonce,
  GetBalance,
  BlockchainType as BlockchainTypeObj,
} from "../utilities/address";
import { GetEquivalentSymbol } from "../utilities/symbols";

const processingStates = {
  initial: "Intial",
  pending: "Pending",
  completed: "Completed",
  failed: "Failed",
};

const btnMsg = {
  ready: "Publish",
  pending: "Submitting",
  completed: "Completed",
};

function RenderDecodedData({
  data,
  isValid,
  showForm,
  errorMessage = Text.RawTx.enterInput,
  errorClass = "info-msg",
}) {
  if (showForm === false) {
    return (
      <>
        <Row>
          <Col className={`u-text-center ${errorClass}`}>{errorMessage}</Col>
        </Row>
      </>
    );
  }

  let decodedInput;
  if (data.data) {
    decodedInput = DecodeData(data.data);

    decodedInput = (
      <Row>
        <Col lg={3} sm={3}></Col>
        <Col lg={9} sm={9}>
          {decodedInput}
        </Col>
      </Row>
    );
  }

  return (
    <>
      {isValid === false ? (
        <>
          <Row>
            <Col className={`u-text-center ${errorClass}`}>{errorMessage}</Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">Chain ID</label>
        </Col>
        <Col lg={9} sm={9}>
          <input disabled className="form-comtrol" value={data.chainId} />
        </Col>
      </Row>

      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">From Address</label>
        </Col>
        <Col lg={9} sm={9}>
          <input disabled className="form-comtrol" value={data.from} />
        </Col>
      </Row>

      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">To Address</label>
        </Col>
        <Col lg={9} sm={9}>
          <input disabled className="form-comtrol" value={data.to} />
        </Col>
      </Row>

      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">Value</label>
        </Col>
        <Col lg={9} sm={9}>
          <div className="appended-input">
            <input disabled className="form-comtrol" value={data.value} />
            <div className="appendage">
              {GetEquivalentSymbol(
                "wei",
                GetBlockchainFromChainId(data.chainId)
              )}
            </div>
          </div>
        </Col>
        <Col lg={3} sm={3}></Col>
        <Col lg={9} sm={9}>
          <div className="appended-input">
            <input disabled className="form-comtrol" value={data.eth} />
            <div className="appendage">
              {GetEquivalentSymbol(
                "ether",
                GetBlockchainFromChainId(data.chainId)
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">Nonce</label>
        </Col>
        <Col lg={9} sm={9}>
          <input disabled className="form-comtrol" value={data.nonce} />
        </Col>
      </Row>
      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">Current Nonce</label>
        </Col>
        <Col lg={9} sm={9}>
          <input disabled className="form-comtrol" value={data.currentNonce} />
        </Col>
      </Row>
      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">Balance</label>
        </Col>
        <Col lg={9} sm={9}>
          <div className="appended-input">
            <input
              disabled
              className="form-comtrol"
              value={data.currentBalance}
            />
            <div className="appendage">
              {GetEquivalentSymbol(
                "wei",
                GetBlockchainFromChainId(data.chainId)
              )}
            </div>
          </div>
        </Col>
        <Col lg={3} sm={3}></Col>
        <Col lg={9} sm={9}>
          <div className="appended-input">
            <input
              disabled
              className="form-comtrol"
              value={data.currentBalanceEth}
            />
            <div className="appendage">
              {GetEquivalentSymbol(
                "ether",
                GetBlockchainFromChainId(data.chainId)
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">{Text.RawTx.gasPrice}</label>
        </Col>
        <Col lg={9} sm={9}>
          <input disabled className="form-comtrol" value={data.gasPrice} />
        </Col>
      </Row>

      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">{Text.RawTx.gasLimit}</label>
        </Col>
        <Col lg={9} sm={9}>
          <input disabled className="form-comtrol" value={data.gasLimit} />
        </Col>
      </Row>

      <Row>
        <Col lg={3} sm={3}>
          <label className="form-comtrol">{Text.RawTx.data}</label>
        </Col>
        <Col lg={9} sm={9}>
          <div className="raw-tx-decoder--field">{data.data}</div>
        </Col>
      </Row>

      {decodedInput}
    </>
  );
}

function renderStatusNote(data) {
  if (data.processing !== true) {
    return <></>;
  }

  if (
    data.status === processingStates.pending ||
    data.status === processingStates.completed
  )
    return (
      <div className="highlighted-note">
        <div className="highlighted-note--title">Status</div>
        <div className="highlighted-note--body">
          <Container>
            <Row>
              <Col lg={4} md={4} sm={4} className="highlighted-note--body_key">
                Status
              </Col>

              <Col
                lg={8}
                md={8}
                sm={8}
                className="highlighted-note--body_field"
              >
                {data.status}
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} sm={4} className="highlighted-note--body_key">
                TX Hash
              </Col>

              <Col
                lg={8}
                md={8}
                sm={8}
                className="highlighted-note--body_field"
              >
                {data.txHash}
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={4} sm={4} className="highlighted-note--body_key">
                Link
              </Col>

              <Col
                lg={8}
                md={8}
                sm={8}
                className="highlighted-note--body_field"
              >
                <a href={data.link} rel="noreferrer" target="_blank">
                  {data.txHash.substr(0, 10)}...
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );

  if (data.status === processingStates.failed)
    return (
      <div className="highlighted-note">
        <div className="highlighted-note--title">Status</div>
        <div className="highlighted-note--body">
          <Container>
            <Row>
              <Col lg={4} md={4} sm={4} className="highlighted-note--body_key">
                Status
              </Col>

              <Col
                lg={8}
                md={8}
                sm={8}
                className="highlighted-note--body_field"
              >
                {data.status}
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} sm={4} className="highlighted-note--body_key">
                Error
              </Col>

              <Col
                lg={8}
                md={8}
                sm={8}
                className="highlighted-note--body_field"
              >
                {data.errorMessage}
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} sm={4} className="highlighted-note--body_key">
                TX Hash
              </Col>

              <Col
                lg={8}
                md={8}
                sm={8}
                className="highlighted-note--body_field"
              >
                {data.txHash}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
}

function renderRedoBtn(data, cb) {
  if (
    true
    // (data.processing === true) &
    // [data.completed, data.failed].includes(data.status)
  ) {
    return (
      <button className="u-float-r btn btn-secondary" onClick={() => cb()}>
        {Text.RawTx.reset}
      </button>
    );
  }

  return <></>;
}

export default function RawTxDecoder() {
  const [rawTx, setRawTx] = useState("");
  const [form, setForm] = useState({
    data: {},
    isValid: false,
    btnMsg: btnMsg.ready,
    processing: false,
    errorMessage: Text.RawTx.enterInput,
    status: "",
    txHash: "",
    showForm: false,
    currentBalance: "loading",
    errorClass: "info-msg",
  });

  useEffect(() => {
    let isValid = false,
      showForm = false,
      errorMessage = Text.RawTx.enterInput,
      errorClass = "info-msg",
      data = {};

    if (!_.isEmpty(rawTx))
      try {
        data = ethers.utils.parseTransaction(rawTx);
        data.gasPrice = data.gasPrice.toString();
        data.gasLimit = data.gasLimit.toString();
        data.value = data.value.toString();
        data.eth = FromWei(data.value);
        data.currentNonce = "loading";
        isValid = true;
        if (data.gasPrice == 0 || data.gasLimit == 0) {
          isValid = false;
          errorMessage = "invalid gas price / limit";
          errorClass = "error-msg";
        }
        showForm = true;
        let type = GetBlockchainFromChainId(data.chainId);
        if (type === BlockchainTypeObj.xinfin) {
          data.from = ToXdcAddress(data.from);
          data.to = ToXdcAddress(data.to);
        }
        GetNonce(data.from, type)
          .then((resp) => {
            const validNonce = data.nonce === resp;
            if (validNonce === false) {
              errorMessage = "invalid nonce";
              errorClass = "error-msg";
            }
            setForm({
              ...form,
              data: { ...data, currentNonce: resp },
              isValid: isValid && validNonce,
              showForm: true,
              errorMessage,
            });

            GetBalance(data.from, type)
              .then((bal) => {
                const validBalance = parseFloat(data.value) <= parseFloat(bal);
                if (validBalance === false) {
                  errorMessage = "invalid balance";
                  errorClass = "error-msg";
                }

                setForm({
                  ...form,
                  data: {
                    ...data,
                    currentNonce: resp,
                    currentBalance: bal,
                    currentBalanceEth: FromWei(bal),
                  },
                  isValid: isValid && validNonce && validBalance,
                  showForm: true,
                  errorMessage,
                  errorClass,
                });
              })
              .catch((e) => {
                errorMessage = "error while getting balance";
                errorClass = "error-msg";

                setForm({
                  ...form,
                  currentBalance: "error",
                  errorClass,
                  isValid: false,
                  errorMessage,
                });
              });
          })
          .catch((e) => {
            errorMessage = "error while getting nonce";
            errorClass = "error-msg";

            setForm({
              ...form,
              currentNonce: "error",
              isValid: false,
              errorMessage,
              errorClass,
            });
          });
      } catch (e) {
        console.error(e);
        data = {};
        isValid = false;
        showForm = false;
        errorMessage = Text.RawTx.inavlidData;
        errorClass = "error-msg";
      }

    setForm({ ...form, data, isValid, showForm, errorMessage, errorClass });
  }, [rawTx]);

  return (
    <div className="raw-tx-decoder">
      <Card className="custom-card-1">
        <Card.Body>
          <Container>
            <div className="title">
              Raw Tx Decoder<div className="title--bottom"></div>
            </div>

            <div className="card-body custom-input-1">
              <Row>
                <Col lg={3} sm={3}>
                  <label className="form-comtrol">Enter Raw TX</label>
                </Col>
                <Col lg={9} sm={9}>
                  <textarea
                    disabled={form.processing}
                    className="form-comtrol"
                    onChange={(e) => setRawTx(e.target.value)}
                    placeholder={Text.RawTx.inputPlaceholder}
                    value={rawTx}
                  />
                  <QRReader onSubmit={(e) => setRawTx(e)} />
                </Col>
              </Row>

              <hr />

              {RenderDecodedData(form)}

              <hr />

              <Row>
                <Col>
                  <button
                    className="u-float-r btn btn-primary"
                    disabled={
                      form.isValid === false || form.btnMsg !== btnMsg.ready
                    }
                    onClick={() => {
                      setForm({
                        ...form,
                        processing: true,
                        btnMsg: btnMsg.pending,
                        state: processingStates.initial,
                      });
                      const type =
                        GetBlockchainFromChainId[`${form.data.chainId}`];
                      const RawTxFunc =
                        type === "xinfin" ? PublishRawTxXdc : PublishRawTxWeb3;
                      RawTxFunc(rawTx, form.data.chainId)
                        .once("transactionHash", function (hash) {
                          setForm({
                            ...form,
                            processing: true,
                            txHash: hash,
                            status: processingStates.pending,
                            link: `${
                              ExplorerTx[`${form.data.chainId}`]
                            }${hash}`,
                          });
                        })
                        .once(
                          "receipt",
                          function (receipt) {
                            if (receipt.status === true) {
                              // tx succcess
                              setForm({
                                ...form,
                                processing: true,
                                status: processingStates.completed,
                                btnMsg: btnMsg.completed,
                                txHash: receipt.transactionHash,
                                link: `${ExplorerTx[`${form.data.chainId}`]}${
                                  receipt.transactionHash
                                }`,
                              }).bind(this);
                            } else {
                              //tx failed
                              setForm({
                                ...form,
                                processing: true,
                                status: processingStates.failed,
                                btnMsg: btnMsg.completed,
                              }).bind(this);
                            }
                          }.bind(this)
                        )
                        .once("error", (err) => {
                          setForm({
                            ...form,
                            processing: true,
                            status: processingStates.failed,
                            errorMessage: err.message,
                            btnMsg: btnMsg.completed,
                          });
                        });
                    }}
                  >
                    {form.btnMsg}
                  </button>
                  {renderRedoBtn({ ...form }, () => {
                    setForm({
                      data: {},
                      isValid: false,
                      btnMsg: btnMsg.ready,
                      processing: false,
                      errorMessage: "Enter Input",
                      status: "",
                      txHash: "",
                      showForm: false,
                    });
                    setRawTx("");
                  })}
                </Col>
              </Row>

              <Row>
                <Col>{renderStatusNote({ ...form })}</Col>
              </Row>
            </div>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
}
