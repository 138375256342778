import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import _ from "lodash";

import { Button, Modal } from "react-bootstrap";

class QRReader extends Component {
  state = {
    result: "",
    showModal: false
  };

  handleScan = data => {
    if (data) {
      this.setState({
        result: data
      });
    }
  };
  handleError = err => {
    console.error("******* error", err);
  };

  renderCamAction = () => {

    if (_.isEmpty(this.state.result)) {
      return <></>;
    }
    return <><Button onClick={() => this.setState({ result: "" })} variant="secondary">Retake</Button><Button onClick={() => { this.setState({ showModal: false }); this.props.onSubmit(this.state.result); }} variant="primary">Ok</Button></>;
  };

  render() {
    return (
      <div>
        <Button onClick={() => this.setState({ showModal: true })} variant="primary">QR Code Scan</Button>
        <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} >
          <Modal.Header closeButton>
            TX Hash Scanner
          </Modal.Header>
          <Modal.Body className="qr-code-modal">
            {_.isEmpty(this.state.result) ? <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '70%' }}
            /> : <p>{this.state.result}</p>}

          </Modal.Body>
          <Modal.Footer>
            {this.renderCamAction()}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default QRReader;
