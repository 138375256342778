import Web3, { utils } from "web3";

import { RPC } from "../helpers/rpc";

const EthUnitMap = Object.keys(utils.unitMap).reduce((acc, cur) => {
  acc[cur] = cur;
  return acc;
}, {});

export const PublishRawTx = (tx, chainId) => {
  const rpc = RPC[`${chainId}`];
  const web3 = new Web3(new Web3.providers.HttpProvider(rpc));
  return web3.eth.sendSignedTransaction(tx);
};

export const FromWei = (n, to = "ether") => {
  return utils.fromWei(n, to);
};

export const GetAccountNonce = (
  address,
  config = { chainId: 1, type: "latest" }
) => {
  const chainId = config.chainId || 1;
  const type = config.type || "latest";
  const rpc = RPC[`${chainId}`];
  const web3 = new Web3(new Web3.providers.HttpProvider(rpc));
  return web3.eth.getTransactionCount(address, type);
};

export const GetAccountBalance = (address) => {
  const chainId = 1;
  const rpc = RPC[`${chainId}`];
  const web3 = new Web3(new Web3.providers.HttpProvider(rpc));
  return web3.eth.getBalance(address);
};

export const IsAddress = (address = "") => {
  return utils.isAddress(address);
};

export const UnitMap = (unit) => {
  return EthUnitMap[unit];
};
